import { default as NextImage, ImageProps } from 'next/image';

const Image = (props: Partial<ImageProps>) => {
  return (
    <>
      <NextImage
        placeholder="blur"
        blurDataURL="/assets/defaults/thumbnails/blur-data-image.jpg"
        width={500}
        height={500}
        alt={''}
        src={''}
        quality={50}
        {...props}
        {...(!props.alt && { alt: 'MeeFund - Cari hal seru sambil cari ilmu!' })}
        {...(!props.src && { src: '/assets/defaults/thumbnails/empty-picture.webp' })}
        {...(!props.priority && { loading: 'lazy' })}
      />
    </>
  );
};

export default Image;
