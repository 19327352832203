'use client';
import Player from 'next-video/player';
import ScrollContainer from 'react-indiana-drag-scroll';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { PhotoViewProps } from 'react-photo-view/dist/PhotoView';
import 'react-photo-view/dist/react-photo-view.css';
import { cn } from '~/libs/tools';
import Image from './Image';

type ImageContainerType = {
  id?: string;
  className?: string;
  media?: { src: string; type: string }[];
  small?: boolean;
  videoAutoPlay?: boolean;
  triggers?: PhotoViewProps['triggers'];
  onMediaClick?: (media: ImageContainerType['media'], index: number) => void;
  onMediaDoubleClick?: (media: string, index: number) => void;
};

const ImageContainer = (props: ImageContainerType) => {
  const temp: any = [
    // { src: '/video.mp4', type: 'video/mp4' },
    // { src: '/video.mp4', type: 'video/mp4' },
    // { src: '/video.mp4', type: 'video/mp4' },
    // { src: '/video.mp4', type: 'video/mp4' },
    // { src: '/video.mp4', type: 'video/mp4' },
    // { src: '/video.mp4', type: 'video/mp4' },
  ];

  const IMGL = props?.media?.length || 0;
  if (IMGL === 0) return null;

  return (
    <PhotoProvider maskOpacity={0.9} className={cn(IMGL == 0 && 'hidden')}>
      <ScrollContainer className={cn(`flex gap-0.5 overflow-x-auto !transition-none`, props.className)}>
        {props?.media?.map((x: any, i: any) => (
          <>
            <PhotoView src={x.src} triggers={props.triggers ?? (['onClick'] as any)}>
              {x.type.startsWith('image/') && (
                <Image
                  priority
                  onClick={(e: any) => {
                    e.stopPropagation();
                    props.onMediaClick?.(x, i);
                  }}
                  onDoubleClick={(e: any) => {
                    e.stopPropagation();
                    props.onMediaDoubleClick?.(x, i);
                  }}
                  src={x.src}
                  className={cn('max-h-[60vh] w-auto rounded-lg object-cover', IMGL > 2 && 'max-h-[50vh]', props.small && 'max-h-[40vh]')}
                  alt={`Image ${i + 1}`}
                />
              )}
            </PhotoView>
            {x.type.startsWith('video/') && (
              <div className={cn('max-h-[60vh] w-auto flex-shrink-0 overflow-hidden rounded-lg', IMGL > 2 && 'max-h-[50vh]', props.small && 'max-h-[40vh]')}>
                <Player src={x.src} muted loop className="h-full w-full rounded-lg" />
              </div>
            )}
          </>
        ))}
      </ScrollContainer>
    </PhotoProvider>
  );
};

export default ImageContainer;
